import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import imageFond from "../../medias/imageorange.jpg"
import imagesombre from "../../medias/sombre2.jpg"
import { Link } from "react-router-dom";
import { FiPlus } from "react-icons/fi";
import "./Home.css";
import YouTubePlayer from "../youtubeplayer/youtubeplayer";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Footer } from "../footer/footer";


function Home() {
  const [showVideo, setShowVideo] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setShowVideo(true);
  };

  const handleClose = () => {
    setShowVideo(false);
  };


  return (
    <div className="App">
      <header className="App-header">
        <div className="hero-section">
          <img
            src={imageFond}
            alt="Electrical Installation"
            className="hero-image"
          />
          <div className="hero-text">
            <div className="title child">
              <span className="title1 every">Empowering Growth through</span>
              <span className="title2">Engineering & Software Solutions</span>
              <span className="title3 every">Serving Client Across Africa.</span>
              <div className="text">
                <p className="paragraphe">
                  Offering top-quality Engineering and software development solutions for businesses of all sizes. Our focus is on delivering reliable, innovative, and cost-effective services to meet your unique needs.
                </p>
              </div>
              <div className="boutons">
                <Link to="/contact" className="one bouton">Contact</Link>
                <Link to="/profile" className="two bouton">Learn More</Link>
              </div>
            </div>
            <div className="signature child">
              <img
                // src={signature}
                alt="Electrical Installation"
                className="hero-image"
              />
            </div>
          </div>
          <div
            className="scroll-down"
            onClick={() =>
              window.scrollTo({ top: window.innerHeight, behavior: "smooth" })
            }
          >
            <div className="arrow-circle">
              <span className="arrow">&#8595;</span>
            </div>
          </div>
          <div className="slogan">
            Future Tech Adaptability
          </div>
        </div>
      </header>

      <section className="activity-image">
        <p className="title">Featured Projects</p>
        <div className="activity-section">
          <div className="card-image everycard one">
            <div className="card-name">
              <p>R&D in Engineering</p>
            </div>
          </div>
          <div className="card-image everycard two">
            <div className="card-name">
              <p>Mechanical Design</p>
            </div>
          </div>
          <div className="card-image everycard three">
            <div className="card-name">
              <p>Web Development</p>
            </div>
          </div>
          <div className="card-image everycard four">
            <div className="card-name">
              <p>PCB and Embedded Firmware Development</p>
            </div>
          </div>
          <div className="card-image everycard five">
            <div className="card-name">
              <p>Writings</p>
            </div>
          </div>
          <div className="card-image six">
            <Link to='contact' className="arrow"><FaLongArrowAltRight /></Link>
            <div className="card-text">
              <p className="tittle">Trust us with your work,</p>
              <p className="texte">and you will be impressed by our unwavering professionalism.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="presentation">
        {/* <div className="cartes">
            <div className="carte1">
              
            </div>
            <div className="carte2">
              
            </div>
            <div className="description">
              
            </div>
        </div> */}
        {
          showVideo && <div className="video-section">
            <YouTubePlayer videoId="CZsVAhWcBew" />
          </div>
        }
        <div className="presentation-section">
          <div className="presentation-one">
            <p className="title">Entrust your project to our experts</p>
            <p className="texte">Established in 2024 in Cape Town, Nexadaptronics specializes in delivering high-quality mechanical design, web and mobile app development, and various writing services across South Africa and the world.
            </p>
            <p className="texte">
              Our mission at Nexadaptronics is to create technologies that empower customers with innovative, reliable solutions.
            </p>
            <div className="youtube">
              {/* <Link to="https://www.youtube.com/watch?v=CZsVAhWcBew" className="youtube-logo">
                <div className="logo-container">
                  <div className="play-button"></div>
                </div>
              </Link> */}
              <span className="logo-text">We're here to support you!</span>
            </div>
          </div>
          <div className="presentation-image">
            <img
              src={imagesombre}
              alt="sombre"
              className="image"
            />
          </div>
        </div>
      </section>

      <section className="service">
        <div className="card">
          <div className="card-section">
            <div className="card-one">
              <FiPlus className="plus" />
              <p className="number">5</p>
              <p className="caractere">domains</p>
            </div>
          </div>
        </div>

        <div className="service-presentation">
          <p className="tittle">Our Services</p>
          <p className="texte">We offer a comprehensive range of services and develop products tailored to meet all your needs.</p>
        </div>

        <div className="service-section">
          <div className="activity">
            <span className="number">01</span>
            <span className="trait">-</span>
            <div className="sumery">
              <p className="title1">R&D in Engineering</p>
              <p className="title2">We have a dedicated team focused on researching and developing innovative solutions and products to meet niche market demands.
              </p>
            </div>
          </div>
          <div className="activity">
            <span className="number">02</span>
            <span className="trait">-</span>
            <div className="sumery">
              <p className="title1">Mechanical Design</p>
              <p className="title2">We offer professional 2D and 3D mechanical design services customized to your project's needs.</p>
            </div>
          </div>
          <div className="activity">
            <span className="number">03</span>
            <span className="trait">-</span>
            <div className="sumery">
              <p className="title1">Web Development</p>
              <p className="title2">We provide web development services tailored to your requirements, whether it's a static or dynamic website, ensuring a robust and reliable solution.</p>
            </div>
          </div>
          <div className="activity">
            <span className="number">04</span>
            <span className="trait">-</span>
            <div className="sumery">
              <p className="title1">PCB and Embedded firmware Development</p>
              <p className="title2">We offer high quality PCB and electrical designs and firmware development using different programming languages.</p>
            </div>
          </div>
          <div className="activity">
            <span className="number">05</span>
            <span className="trait">-</span>
            <div className="sumery">
              <p className="title1">Writings</p>
              <p className="title2">We assist in doing risks assessments , business profile, project timeline, poster , business profile , video text adverts ; formatting both books and other writings, refining both structure and content. We also write quotes, poems , songs, and more to suit your needs.</p>
            </div>
          </div>
          {/* <div className="activity">
            <span className="number">04</span>
            <span className="trait">-</span>
            <div className="sumery">
              <p className="title1">Specialized Energy Solutions</p>
              <p className="title2">Explore sustainable options with solar installations,
                inverter systems, and installation of Standby Generators and automatic change over switch.</p>
            </div>
          </div>
          <div className="activity">
            <span className="number">05</span>
            <span className="trait">-</span>
            <div className="sumery">
              <p className="title1">Specialized Energy Solutions</p>
              <p className="title2">Explore sustainable options with solar installations,
                inverter systems, and installation of Standby Generators and automatic change over switch.</p>
            </div>
          </div> */}
        </div>
        {/* <p>
          Founded in 2017 in Cape Town, Joatech Electrical specializes in
          delivering high-quality electrical construction and maintenance
          services across South Africa, the DRC, and the rest of Africa.
        </p>
        <div className="cta-buttons">
          <button onClick={() => navigate("/profile")}>Learn More</button>{" "}
          <button onClick={() => navigate("/services")}>
            Our Services
          </button>
        </div> */}
      </section>



      {/* <section className="featured-projects shared-background">
        <h2>Featured Projects</h2>
        <div className="projects-container">
          <div className="project-card">
            <img
              src="LightingDesignAndAutomation.png"
              alt="Lighting Design and Automation"
            />
            <p>Lighting Design and Automation</p>
          </div>
          <div className="project-card">
            <img src="DBBoardInstallation.png" alt="DB Board Installation" />
            <p>DB Board Installation</p>
          </div>
          <div className="project-card">
            <img
              src="IndustrialCableTrayInstallation.png"
              alt="Industrial Cable Tray Installation"
            />
            <p>Industrial Cable Tray Installation</p>
          </div>
          <div className="project-card">
            <img
              src="PrecisionandCableManagement.png"
              alt="Precision and Cable Management"
            />
            <p>Precision and Cable Management</p>
          </div>
          <div className="project-card">
            <img
              src="AccurateInstallationonOff-ShutterStructure.png"
              alt="Accurate Installation on Off-Shutter Structure"
            />
            <p>Accurate Installation on Off-Shutter Structure</p>
          </div>
        </div>
      </section> */}
      {/* <footer className="footer">
        <div className="footer-links">
          <a href="/profile">About Us</a>
          <a href="/services">Services</a>
          <a href="/contact">Contact</a>
        </div>
        <p>&copy; 2024 Joatech Electrical. All Rights Reserved.</p>
      </footer> */}
    </div>
  );
}

export default Home;