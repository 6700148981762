import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../../medias/logo1.png';
import { IoMenu } from "react-icons/io5";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Fonction pour fermer le menu lorsqu'un lien est cliqué
  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbare">
      <div className='navbaresection'>
        <div className="navbarlogo">
          <Link to="/"><img src={logo} alt="Nexadaptronics Logo" className='image' /></Link>
        </div>
        <div className={`link ${isOpen ? 'open' : ''}`}>
          <ul className={`nav-menue ${isOpen ? 'open' : ''}`}>
            <li className="nav-item" onClick={closeMenu}><Link to="/">Overview</Link></li>
            <li className="nav-item" onClick={closeMenu}><Link to="/profile">Profile</Link></li>
            <li className="nav-item" onClick={closeMenu}><Link to="/contact">Contact</Link></li>
          </ul>
        </div>
        <div className="menu-icone" onClick={toggleMenu}>
          <IoMenu />
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
