import React from 'react';
import './Profile.css';  // Make sure this path is correct
import logo from "../../medias/AccurateInstallationonOff-ShutterStructure.png"
function Profile() {
  return (
    <div className="profile">
      <section className='presentation'>
        <div className='presentation-sectionn'>
          <p className='tittle1'>put your trust in us </p>
          <p className='tittle2'>Innovative solutions in Mechatronics, PCB design and firmware development, Mechanical design, Web development, R&D, and writings services. Let’s build a smarter future together.</p>
          <p className='tittle3'>Expert services tailored to your project's needs, from cutting-edge mechanical design to seamless web and app development. Your success is our priority.</p>
        </div>
      </section>
      <section className='details'>
        <div className='details-section'>
          <div className='card'>
            <div className='bloc'>
              <p className='tittle1'>Mission</p>
              <p className='tittle2'>Nexadaptronics is dedicated to developing groundbreaking technologies, devices that are not only ahead of their time but also supremely adaptable to the changing needs of businesses and societies. Our mission is to empower our customers with the tools and solutions that are at the forefront of technological innovation, offering unparalleled efficiency, reliability, and performance.
              </p>
            </div>
          </div>
          <div className='card'>
            <div className='bloc'>
              <p className='tittle1'>Vision</p>
              <p className='tittle2'>Our vision is to redefine the boundaries of technology by pioneering innovative solutions that adapt seamlessly to the evolving demands of our world, ensuring that every advancement propels us towards a smarter, more connected future.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='galery'>
        <p className='text'>Service Gallery</p>
        <div className='galery-section'>
          <div className='card'>
            <img
              src={require('../../medias/mechanical.jpg')}
              alt="Mechanical Engineer"
            />
          </div>
          <div className='card'>
            <img
              src={require('../../medias/pcb.jpg')}
              alt="PCB"
            />
          </div>
          <div className='card'>
            <img
              src={require('../../medias/webdev.jpg')}
              alt="Web Dev"
            />
          </div>
          <div className='card'>
            <img
              src={require('../../medias/Writer.jpg')}
              alt="Writer"
            />
          </div>
        </div>

      </section >
      {/* 
      <section className="mission-vision">
        <h2>Mission & Vision</h2>
        <p>Mission Statement: To be recognized as a leading Electrical contracting company in South Africa and DRC, through our services and customer satisfaction.</p>
        <p>Vision Statement: Committed to the needs of our clients, delivering quality jobs on time in a professional manner, and bringing solutions to all your home technology problems.</p>
      </section>

      <section className="goals">
        <h2>Our Goals</h2>
        <ul>
          <li>Strong leadership</li>
          <li>Community work</li>
          <li>Respect for the environment</li>
          <li>Management system improvement</li>
          <li>Employee development</li>
        </ul>
      </section>

      <section className="history-background">
        <h2>History & Background</h2>
        <p>Joatech Electrical was established in 2017 in Cape Town. Over the years, we have grown to become a recognized leader in electrical contracting in South Africa and the DRC.</p>
        <p className="contact-email">Contact Email: joatech.el@gmail.com</p>
      </section> */}
    </div >
  );
}

export default Profile;
