import { Link } from "react-router-dom";
import "./footer.css";
import { FaWhatsapp } from "react-icons/fa";

export function Footer() {
  return (
    <footer className="footer">
      <div className="what">
        <div className="link">
          <a href="https://wa.me/27729378309" target="_blank"><FaWhatsapp className="icone" /></a>
        </div>
      </div>
      <div className="footer-links">
        <a href="/profile">About Us</a>
        {/* <a href="/services">Services</a> */}
        <a href="/contact">Contact</a>
      </div>
      <p>Copyright &copy; 2024 Nexadaptronics (Pty) Ltd 2024/415088/07. All Rights Reserved. ||Designed by Nexadaptronics.</p>
    </footer>
  )
}