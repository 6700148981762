import React, { useRef, useEffect, useState } from 'react';
import './Contact.css';
import emailjs from "@emailjs/browser";

function Contact() {
  const subjectRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    emailjs.init("o7RicTwK9z-wZIF2m"); // Your EmailJS public key
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const serviceId = "service_mc3sngr";
    const templateId = "template_3fdta1j";

    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, {
        subject: subjectRef.current?.value,
        email: emailRef.current?.value,
        message: messageRef.current?.value,
      });

      // Clear the input fields after sending
      if (subjectRef.current) subjectRef.current.value = "";
      if (emailRef.current) emailRef.current.value = "";
      if (messageRef.current) messageRef.current.value = "";

      // Show success message
      setSuccessMessage('Your message has been sent successfully!');

    } catch (error) {
      console.log(error);
      alert("Failed to send the email. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-page">
      <div className="contact-form-container">
        <h1>Contact Us</h1>
        <p>Drop us a message, or check out our footer for more contact information.</p>

        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <input ref={subjectRef} type="text" placeholder="Subject" />
            <input ref={emailRef} type="email" placeholder="Your email address" />
            <textarea ref={messageRef} placeholder="Your message"></textarea>
            <button type="submit" disabled={loading}>
              {loading ? "Sending..." : "Contact Us"}
            </button>
          </form>
          {successMessage && <p className="success-message">{successMessage}</p>}
        </div>
      </div>

      <div className="careers-container">
        <h2>CAREERS</h2>
        <div className="career-card">
          <img src="career.png" alt="Electrical Icon" />
          <h3>No Vacancies</h3>
          <p>There are currently no vacancies available. Check back soon!</p>
        </div>
      </div>
    </div>
  );
}

export default Contact;
